import roller from 'common/module/ui/roller';

import applicationBootstrap from 'website/bootstrap';
// autocomplete Localisation
import initializeLocationSearch from 'website/containers/showtimes/searchLocationAutocomplete';
// theaters / showtimes UI
import theaterButtons from 'website/containers/user/theaterButtons';
import carousel from 'website/module/ui/carousel';
import dropdownLinks from 'website/module/ui/dropdown-custom';
import loadAccesLibre from 'website/third_party/acces-libre';

// bootstrap the application / store
applicationBootstrap().then(() => {
  carousel();
  roller();
  theaterButtons();
  dropdownLinks();
  initializeLocationSearch();
  loadAccesLibre();
});
